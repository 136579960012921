iframe {
    width: 100vw;
    height: 100vh;
    border: none;
    overflow: hidden;
}

h1 {
    font-weight: 700;
    font-size: 7rem;
    margin: 0;
}
h2 {
    font-weight: 600;
    font-size: 5rem;
    color: var(--lightblue);
    margin: 0;
}

h3 {
    font-weight: 600;
    font-size: 4.5rem;
    color: var(--lightblue);
    margin: 0;
}

h4 {
    font-weight: 400;
    font-size: 3rem;
    color: var(--darkBlue);
    margin: 0;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

img {
    height: 100vh;
    overflow-y: hidden;
}