@import url("https://use.typekit.net/rhb1dgr.css");

body {
  --darkBlue: #0a6bf0;
  --lightblue: #73aaf1;
  margin: 0;
  font-family: capitana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ddeaf3;
  text-align: center;
  color: #0a6bf0;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
